import { LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import Hero from '@components/Hero/Hero';
import Columns from '@components/Layout/Columns/Columns';
import Content, { MotionContent } from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Accordion from '@components/Molecule/Accordion/Accordion';
import Copy from '@components/Typography/Copy';
import { MotionCopy } from '@components/Typography/Copy';
import H from '@components/Typography/H';
import { MotionH } from '@components/Typography/H';

const SupportPage = ({ data }: { data: any }) => {
	return (
		<Page transparent={false}>
			<SEO
				title="Support | UHV Design"
				description="Our customer support team are here to help. So please don't hesitate to get in touch for product support, advice or immediate assistance. Contact us."
			/>

			<Hero
				image={{
					src: getImage(data.hero),
					alt: 'Support staff working at their desks',
				}}
				type="box">
				<Content>
					<MotionH level={2} as="h1">
						Support
					</MotionH>

					<MotionCopy spacing="sm">
						Our customer support team are here to help. Please don't
						hesitate to get in touch with us if you need product
						support, advice, or immediate assistance.
					</MotionCopy>
				</Content>

				<Content>
					<MotionH level={3} as="h2">
						Product Support
					</MotionH>

					<MotionCopy spacing="sm">
						We have a dedicated{' '}
						<Link to={LINKS.PRODUCT_HELP}>Product help</Link>{' '}
						section which contains technical specifications and
						product manuals where applicable. If the information you
						need is not available in this section, please contact us
						at{' '}
						<a href="mailto:sales@uhvdesign.com">
							sales@uhvdesign.com
						</a>{' '}
						and we will typically respond within 24 hours. If your
						enquiry is urgent, please call us on{' '}
						<a href="tel:+44 (0)1323 811188">+44 (0)1323 811188</a>{' '}
						and select the option for 'Sales'.
					</MotionCopy>
				</Content>
			</Hero>

			<PageSection background="grey">
				<Columns stretch="latter">
					<MotionH level={3} color="blue">
						Advice
					</MotionH>

					<MotionCopy>
						We have an extensive range of products and we appreciate
						that sometimes it is not always obvious which option is
						the most suitable. If you are unsure, please do not
						hesitate to contact us and we will always be happy to
						offer guidance and advice based on your particular
						application.
					</MotionCopy>
				</Columns>
			</PageSection>

			<PageSection background="grey">
				<Columns stretch="latter">
					<MotionH level={3} color="blue">
						Immediate assistance
					</MotionH>

					<Grid>
						<MotionCopy>
							If you require immediate assistance, please read the
							information below and then contact us by email or
							phone and we will help you as soon as possible. What
							initial details are required to provide immediate
							assistance?
						</MotionCopy>

						<Accordion label="What initial details are required to provide immediate assistance?">
							<Copy as="ul" spacing="none">
								<li>The part number of the item.</li>
								<li>
									The serial number (this will be found on the
									periphery of the product mounting flange)
								</li>
								<li>
									A summary of any issues experienced, with as
									much information as possible (see below for
									examples).
								</li>
								<li>If there are any suspected causes.</li>
								<li>
									Photos and/or videos where possible, showing
									the aspects in question.
								</li>
							</Copy>
						</Accordion>

						<Accordion label="Examples of further information that will help us resolve your issue:">
							<Copy as="ul" spacing="none">
								<li>
									When does the issue present itself? (e.g.
									only during the last two inches of travel,
									when tested in atmosphere).
								</li>
								<li>
									Any information regarding usage of the item
									or system during/prior to the issue
									appearing (e.g. after excessive bakeout,
									after collision with a transfer arm).
								</li>
								<li>
									Any additions/modifications to the unit that
									may be relevant (e.g. a load mounted to the
									output shaft, the end cap has been replaced
									with a custom one with extended screws).
								</li>
								<li>
									Any surrounding components that may affect
									the product in question (e.g. surrounding
									shielding that may be a cause of friction,
									an external heat source).
								</li>
								<li>
									Any time pressures or limitations that we
									should be aware of (e.g. the system is due
									to ship in X weeks, the item cannot be
									demounted).
								</li>
							</Copy>
						</Accordion>
					</Grid>
				</Columns>
			</PageSection>

			<PageSection>
				<Columns stretch="latter">
					<MotionH level={3} color="blue">
						Contact support
					</MotionH>

					<Grid type="fixed">
						<MotionCopy as="address">
							UHV DESIGN LTD
							<br />
							Judges House
							<br />
							Lewes Road
							<br />
							Laughton
							<br />
							East Sussex
							<br />
							England, BN8 6BN
						</MotionCopy>

						<MotionContent>
							<a href="tel:+44 (0)1323 811188">
								T: +44 (0)1323 811188
							</a>

							<a href="fax:+44 (0)1323 811999">
								F: +44 (0)1323 811999
							</a>

							<a href="mailto:sales@uhvdesign.com">
								E: sales@uhvdesign.com
							</a>
						</MotionContent>
					</Grid>
				</Columns>
			</PageSection>

			<PageSection background="blue">
				<Copy weight="bold" font="xl2" align="center" prose={true}>
					If you would like to discuss your project with our
					engineering team, please{' '}
					<Link to={LINKS.CONTACT} className="underline">
						contact us
					</Link>
					.
				</Copy>
			</PageSection>
		</Page>
	);
};

export const query = graphql`
	query {
		hero: file(relativePath: { eq: "support/hero.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;

export default SupportPage;
